import { Dispatch } from "react";

interface IAuthApp {
    title: string;
    loginTitle: string;
}

interface IAuthComponents {
    CustomComponentGenerator: () => JSX.Element;
    LoginComponentGenerator?: (loginFn: Function, setUsernameFn: Function, setPasswordFn: Function) => JSX.Element;
    ErrorComponent?: () => JSX.Element | any;
    HeaderComponent?: () => JSX.Element | any;
}

interface IGraphQLBody {
    query: string;
    variables: any;
}

interface IAuthLogin {
    url: string;
    data: IGraphQLBody | any;
    headers: any;
    responseCallback?: any;
    errorCallback?: any;
    logout?: any;
}

export class AuthSettingModel {
    constructor(
        app: IAuthApp,
        components: IAuthComponents,
        login: IAuthLogin
    ) {
        this.app = app;
        this.components = components;
        this.login = login;
    }
    app: IAuthApp;
    components: IAuthComponents;
    login: IAuthLogin;

}

export class CrudSettingModel {
    constructor(
        initialCrudState: CrudStateModel,
        Form: any,
        ListGenerator: any,
        filterFn: any,
        loadAllData: any,
        addCrud: any,
        loadOneCrud: any,
        editCrud: any,
        deleteCrud: any
    ) {
        this.initialCrudState = initialCrudState;
        this.Form = Form;
        this.ListGenerator = ListGenerator;
        this.filterFn = filterFn;
        this.loadAllData = loadAllData;
        this.addCrud = addCrud;
        this.loadOneCrud = loadOneCrud;
        this.editCrud = editCrud;
        this.deleteCrud = deleteCrud;
    }
    initialCrudState: CrudStateModel;
    Form: (props: any) => JSX.Element;
    ListGenerator: (crudState: CrudStateModel, dispatchCrud: Dispatch<any>, mode: ModeModel) => JSX.Element;
    filterFn: (item: any, filterObject: FilterModel) => any;
    loadAllData: (appReducer: IAppReducer, crudReducer: ICrudReducer, mounted: boolean) => void;
    addCrud: (appReducer: IAppReducer, crudReducer: ICrudReducer, data: any) => void;
    loadOneCrud: (appReducer: IAppReducer, crudReducer: ICrudReducer, setThisCrud: any) => void;
    editCrud: (appReducer: IAppReducer, crudReducer: ICrudReducer, data: any) => void;
    deleteCrud: (appReducer: IAppReducer, crudReducer: ICrudReducer, data: any) => void;
}

export const MODE = {
    LIST: 'List',
    ADD: 'Add',
    EDIT: 'Edit'
};

export interface IAppReducer {
    appState: any;
    dispatchApp: any;
}

export interface ICrudReducer {
    crudState: any;
    dispatchCrud: any;
}

export class ModeModel {
    constructor(

    ) {
        this.List = MODE.LIST;
        this.Add = MODE.ADD;
        this.Edit = MODE.EDIT;
    }
    List: string;
    Add: string;
    Edit: string;
}

export class FilterModel {
    constructor(key: string, value: string) {
        this.key = key;
        this.value = value;
    }
    key: string;
    value: string;
}

export class BreadcrumbModel {
    constructor(
        part1: string,
        part2: string
    ) {
        this.part1 = part1;
        this.part2 = part2;
    }
    part1: string;
    part2: string;
}

export class CrudStateModel {
    constructor(
        paginationLimit: number,
        breadcrumbHome: string
    ) {
        this.loading = false;
        this.error = false;
        this.errorMessage = '';
        this.allData = [];
        this.allDataFilter = [];
        this.allDataPagination = [];
        this.paginationLimit = paginationLimit;
        this.paginationPage = 1;
        this.paginationTotalPages = 1;
        this.mode = MODE.LIST;
        this.editId = '';
        this.breadcrumb = new BreadcrumbModel(MODE.LIST, breadcrumbHome);
        this.breadcrumbHome = breadcrumbHome;
        this.filter = new FilterModel('', '');
    }
    loading: boolean;
    error: boolean;
    errorMessage: string;
    allData: any[];
    allDataFilter: any[];
    allDataPagination: any[];
    paginationLimit: number;
    paginationPage: number;
    paginationTotalPages: number;
    mode: string;
    editId: string;
    breadcrumb: BreadcrumbModel;
    breadcrumbHome: string;
    filter: FilterModel;
}
