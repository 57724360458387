import React, { useState } from 'react';
import Axios, { AxiosResponse, AxiosError } from 'axios';
import { AuthSettingModel } from '../CRUD/Model';
function Authentication(props: any) {
    const { appState, dispatchApp } = props.reducer;
    const setting = props.setting as AuthSettingModel;
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    
    // API integration - start
    const defaultLoginResponseHandler = (i: AxiosResponse) => {
        const data = i.data;
        if (data.data === undefined || data.data === null) {
            console.log('error');
            const errorMessage = data.errors.map((j: any) => {
                const itemMessage = j.message;
                // const itemExtension = i.extensions.code;
                // return `${itemExtension}: ${itemMessage}`;
                return `${itemMessage}`;
            }).join(', ');
            dispatchApp({ type: 'LOGIN_ERROR', payload: errorMessage });
        } else {
            const accountData = JSON.stringify(data.data.login);
            localStorage.setItem('account', accountData);
            dispatchApp({ type: 'LOGIN_SUCCESS', payload: JSON.parse(accountData) });
        }
    }
    const loginResponseHandler = setting.login.responseCallback === undefined ? defaultLoginResponseHandler : setting.login.responseCallback;

    const defaultLoginErrorHandler = (i: AxiosError) => {
        console.log(i);
        dispatchApp({ type: 'LOGIN_ERROR', payload: JSON.stringify(i) });
    }

    const loginErrorHandler = setting.login.errorCallback === undefined ? defaultLoginErrorHandler : setting.login.errorCallback;

    const login = (e?: Event) => {
        !!e ? e.preventDefault() : console.log('No event is fired!');
        dispatchApp({ type: 'START' });
        setUsername('');
        setPassword('');        
        Axios.post(setting.login.url, setting.login.data(username, password), {
            headers: setting.login.headers
        }).then(loginResponseHandler).catch(loginErrorHandler);
    }

    const defaultLogout = () => {
        localStorage.removeItem('account');
        dispatchApp({ type: 'LOGOUT' });
    }

    const logout = setting.login.logout === undefined ? defaultLogout : setting.login.logout;
    // API integration - end

    // child components - start
    const loginComponent = setting.components.LoginComponentGenerator === undefined ? (loginFn: () => void, setUsernameFn: Function, setPasswordFn: Function) => {
        return (
            <form onSubmit={loginFn} className="margin-top-20">
                <div className="form-group">
                    {/*<label htmlFor="username">Username</label>*/}
                    <input type="text" className="form-control" id="username" placeholder="Username" value={username} onChange={(e) => setUsernameFn(e.target.value)} />
                </div>
                <div className="form-group">
                    {/*<label htmlFor="password">Password</label>*/}
                    <input type="password" className="form-control" id="password" placeholder="Password" value={password} onChange={(e) => setPasswordFn(e.target.value)} />
                </div>
                <button type="submit" className="btn btn-primary">Login</button>
            </form>
        )
    } : setting.components.LoginComponentGenerator;
    const errorComponent = setting.components.ErrorComponent === undefined ? () => {
        return (
            <div style={{ marginTop: '10px' }}>
                <span style={{ color: 'red' }}>{appState.errorMessage}</span>
            </div>
        )
    } : setting.components.ErrorComponent;
    const authHeaderComponent = setting.components.HeaderComponent === undefined ? () => {
        return (
            !!appState.auth ? <div className="width-100">
                <p style={{ display: 'none' }}><b>Username: </b>{appState.account.username}</p>
                <p style={{ display: 'none' }}><b>Email: </b>{appState.account.email}</p>
                <div className="row">
                    <div className="col-6 text-left">
                        <i>Welcome back, {appState.account.firstName} {appState.account.lastName}! <span style={{ display: 'none' }}>({appState.account.role})</span></i>
                    </div>
                    <div className="col-6 text-right">{logoutComponent}</div>
                </div>
            </div> : ''
        ) 
    } : setting.components.HeaderComponent;
    const loadingComponent = (
        !!appState.loading ?            
            <button style={{ marginTop: '15px' }} className="btn btn-primary" type="button" disabled>
                <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                Loading...
            </button>
            : ''
    )
    const logoutComponent = (
        <button type="button" className="btn btn-danger" onClick={logout}>Logout</button>
    )

    // child components - end
    return (
        <>
            <header className="margin-top-20 flex-center-all break-all">
            { !!appState.auth ?<h1>{setting.app.title}</h1> : <h1>{setting.app.loginTitle}</h1> }
            
                {authHeaderComponent()}
            </header>

            <div className="flex-center-all">
                {loadingComponent}
            </div>
            {!appState.loading ?
                <main className="flex-center-all">
                    {!appState.auth ? loginComponent(login, setUsername, setPassword) : ''}
                    {setting.components.CustomComponentGenerator()}
                    {!!appState.error ? errorComponent() : ''}
                </main>
                : ''
            }
        </>
    )
}
export default Authentication;