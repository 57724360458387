import React, { useReducer } from 'react';
import { crudReducerGenerator } from './Reducer';
import { ModeModel, CrudStateModel, CrudSettingModel } from './Model';
import List from './List';
import Add from './Add';
import Edit from './Edit';
import Breadcrumb from './Breadcumb';
function CrudContainer(props: any) {  
    const setting = props.setting as CrudSettingModel;  
    const initial = new CrudStateModel(setting.initialCrudState.paginationLimit, 
        setting.initialCrudState.breadcrumbHome);  
    const crudReducer = crudReducerGenerator(
        setting.filterFn
    );
    const [crudState, dispatchCrud] = useReducer(crudReducer, initial);
    const MODE = new ModeModel();
    return (
        <>
            <Breadcrumb reducer={ { bcState: crudState, bcDispatch: dispatchCrud} } />            
            { crudState.mode === MODE.List ? <List appReducer={props.reducer} crudReducer={{ crudState, dispatchCrud}} loadAllData={setting.loadAllData} ListGenerator={setting.ListGenerator} /> : ''}
            { crudState.mode === MODE.Add ? <Add appReducer={props.reducer} crudReducer={{ crudState, dispatchCrud}} addCrud={setting.addCrud} Form={setting.Form} /> : ''}
            { crudState.mode === MODE.Edit ? <Edit appReducer={props.reducer} crudReducer={{ crudState, dispatchCrud}} loadOneCrud={setting.loadOneCrud} editCrud={setting.editCrud} deleteCrud={setting.deleteCrud} Form={setting.Form} /> : ''}
            {!!crudState.loading ? 
                <div className="row flex-center-all">
                    <div className="spinner-grow text-danger" role="status">
                        <span className="sr-only">Loading...</span>
                    </div> 
                </div>
            : ''
            }            
        </>
    )
}
export default CrudContainer;