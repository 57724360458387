import React, { useEffect } from 'react';
import Pagination from './Pagination';
import { ModeModel } from './Model';
function List(props: any) {
    const {crudState, dispatchCrud} = props.crudReducer;  
      
    const MODE = new ModeModel();
    useEffect(() => {
        let mounted = true;
        dispatchCrud({ type: 'GET_ALL_START'});
        props.loadAllData(props.appReducer, props.crudReducer, mounted);
        return () => {
                mounted = false;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    //pagination
    const onPaginationChange = (page: number) => {
        dispatchCrud({ type: 'CHANGE_PAGE', payload: page});
    }
    return (
        <>
        {!crudState.loading ? 
            <div className="row">
                <div className="col-10 text-left">
                <button style={{ display: 'none' }} type="button" className="btn btn-danger">Reload</button>
                </div>            
                <div className="col-2 text-right"><button type="button" className="btn btn-info" onClick={() => dispatchCrud({ type: 'CHANGE_MODE', payload: MODE.Add})}>+</button></div>
            </div>
        : ''} 
        {!!crudState.loading ?
            <div className="spinner-grow text-danger" role="status">
                <span className="sr-only">Loading...</span>
            </div>                
        : '' }
        { crudState.allData.length > 0 && !crudState.loading ? 
            (
                <div>
                { props.ListGenerator(crudState, dispatchCrud, MODE) }
                <div className="flex-center-all margin-top-20">
                <Pagination currentPage={crudState.paginationPage} totalPages={crudState.paginationTotalPages} onChange={onPaginationChange} />
                </div>
                 
                </div>
            )
            : 
            ''
        }
        </>
    )
}
export default List;