import React from 'react';
import { Pagination } from 'react-bootstrap';
function generateShowRange(totalPages: number, currentPage: number) {
    let showRange = [];    
    for (let i = 1; i <= totalPages; i++) {
        if (i === currentPage) {
            showRange.push(i);    
        } else if (i === currentPage - 1) {
            showRange.push(i);    
        } else if (i === currentPage - 2) {
            showRange.push(i);    
        } else if (i === currentPage + 1) {
            showRange.push(i);    
        } else if (i === currentPage + 2) {
            showRange.push(i);    
        } else {
            showRange.push(0);           
        }        
    }
    let newShowRange: number[] = [];
    for (let i = 0; i < showRange.length; i++) {
        const item = showRange[i];
        if (i + 1 === showRange.length) {
            const willPush1 = totalPages;
            const willPush2 = totalPages - 1;
            const willPush3 = totalPages - 2;
            if (!newShowRange.includes(willPush3)) {
                newShowRange.push(willPush3);
            }
            if (!newShowRange.includes(willPush2)) {
                newShowRange.push(willPush2);
            }            
            if (!newShowRange.includes(willPush1)) {
                newShowRange.push(willPush1);
            }
            continue
        }
        if (i === 0) {
            newShowRange.push(item);
            continue;
        }
        const previousItem = showRange[i - 1];
        if (item !== previousItem) {
            newShowRange.push(item);
        }
        
    }
    const preFilter = [...newShowRange.filter(i => i >= 0)];
    if ((preFilter.includes(1) || preFilter.includes(2) || preFilter.includes(3)) && preFilter.length <= 4) {
        return preFilter.filter(i => i !== 0);
    }
    return preFilter;
}
function PaginationComponent(props: any) {    
    const totalPages = props.totalPages;
    const currentPage = props.currentPage;
    

    const paginationItemComponent = (pageNumber: number, active: boolean, key: number) => {
        if (pageNumber === 0) {
            return <Pagination.Ellipsis key={key} />;
        }
        if (!!active) {
            return <Pagination.Item key={key} active>{pageNumber}</Pagination.Item>
        } else {
            return <Pagination.Item key={key} onClick={() => props.onChange(pageNumber)}>{pageNumber}</Pagination.Item>
        }
    }
    
    const showRange = generateShowRange(totalPages, currentPage);
    
    const handlePageNumber = (page: number) => {
        if (page <= 0) {
            return 1;
        }
        if (page >= totalPages) {
            return totalPages;
        }
        return page;
    }
    return (
        <Pagination>
            <Pagination.First onClick={() => props.onChange(1)} />
            <Pagination.Prev onClick={() => props.onChange(handlePageNumber(currentPage - 1))} />
            {
                showRange.map(i => {
                    const key = new Date().getTime() + i + (1000000 * Math.random());
                    return (
                        paginationItemComponent(i, currentPage === i, key)
                    )
                })
            }
            <Pagination.Next onClick={() => props.onChange(handlePageNumber(currentPage + 1))}/>
            <Pagination.Last onClick={() => props.onChange(handlePageNumber(totalPages))}/>
        </Pagination>
    )
}

export default PaginationComponent;