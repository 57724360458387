import React from 'react';
function Add(props: any) {   
    const { dispatchCrud} = props.crudReducer;    
    const Form = props.Form;
    const onSubmitAdding = (data: any) => {
        props.addCrud(props.appReducer, props.crudReducer, {
            ...data            
        });
        dispatchCrud({ type: 'BREADCRUMB_RETURN_HOME'})
    };    
    
    return (
        <div>
        <span><b>Add Country</b></span>
        <Form form={{}} onSubmit={onSubmitAdding} />
        </div>
    )
}
export default Add;