import { CrudStateModel, ModeModel, FilterModel } from "./Model";

export const crudStateGenerator = (paginationLimit: number, breadcrumbHome: string) => {
    return new CrudStateModel(paginationLimit, breadcrumbHome);
}

const calculatePaginationTotalPages = (data: Array<any>) => {
    const roundNumber = [...data].length / 10;
    const isRound = [...data].length % 10 === 0;
    const calculatedTotalPages = Math.floor(roundNumber);
    if (!!isRound) {
        return calculatedTotalPages - 1;
    }
    return calculatedTotalPages;
}

export const GET_ALL_START = 'GET_ALL_START';
export const GET_ALL_SUCCESS = 'GET_ALL_SUCCESS';
export const GET_ALL_FAIL = 'GET_ALL_FAIL';
export const CHANGE_PAGE = 'CHANGE_PAGE';
export const FILTER = 'FILTER';
export const CHANGE_MODE = 'CHANGE_MODE';
export const BREADCRUMB_RETURN_HOME = 'BREADCRUMB_RETURN_HOME';
export const START = 'START';
export const END = 'END';

interface GetAllStartAction {
    type: typeof GET_ALL_START;    
}
interface GetAllSuccessAction {
    type: typeof GET_ALL_SUCCESS;
    payload: Array<any>;
}

interface GetAllFailAction {
    type: typeof GET_ALL_FAIL;
    payload: any;
}

interface ChangePageAction {
    type: typeof CHANGE_PAGE;
    payload: number;
}

interface FilterAction {
    type: typeof FILTER;
    payload: FilterModel;
}

interface ChangeModeAction {
    type: typeof CHANGE_MODE;
    payload: string;
    editId?: string;
}

interface ReturnHomeAction {
    type: typeof BREADCRUMB_RETURN_HOME;    
}

interface StartAction {
    type: typeof START;
}

interface EndAction {
    type: typeof END;
}

export type CrudActions = GetAllStartAction | 
                        GetAllSuccessAction | 
                        GetAllFailAction | 
                        ChangePageAction | 
                        FilterAction | 
                        ChangeModeAction | 
                        ReturnHomeAction |
                        StartAction |
                        EndAction;

export const crudReducerGenerator = (filterFn: Function) => {
    const crudReducer = (state: CrudStateModel, action: CrudActions): any => {
        const MODE = new ModeModel();
        switch (action.type) {
            case GET_ALL_START:
                return {
                    ...state,
                    loading: true
                }
            case GET_ALL_SUCCESS:
                const allDataGetAll = action.payload.sort();                
                return {
                    ...state,
                    allData: [...allDataGetAll] as any[],  
                    allDataFilter: [...allDataGetAll],  
                    allDataPagination: [...allDataGetAll].slice(0, state.paginationLimit),
                    paginationTotalPages: calculatePaginationTotalPages([...allDataGetAll]),
                    loading: false,
                    error: false,
                    errorMessage: ''
                }
            case GET_ALL_FAIL:
                return {
                    ...state,
                    allData: [] as any[],
                    error: true,
                    errorMessage: action.payload
                }
            case CHANGE_PAGE:            
                const changePageCountriesPagination = [...state.allDataFilter].slice(state.paginationLimit * action.payload, (state.paginationLimit * action.payload) + state.paginationLimit);
                return {
                    ...state,
                    paginationPage: action.payload,
                    allDataPagination: changePageCountriesPagination
                }   
            case FILTER:
                if (action.payload === undefined) {
                    console.log('Invalid Filter Payload, no value is found!');
                    return {
                        ...state
                    };
                }
                const filtered = action.payload.value === '' ? [...state.allData] : [...state.allData].filter(i => filterFn(i, action.payload));
                const currentFilterTotalPages = calculatePaginationTotalPages([...filtered]);
                const filteredPagination  = filtered.slice(0, state.paginationLimit);
                return {
                    ...state,
                    allDataFilter: filtered,
                    allDataPagination: filteredPagination,
                    paginationPage: 1,
                    filter: action.payload,
                    paginationTotalPages: currentFilterTotalPages === 0 ? 1 : currentFilterTotalPages,
                }
            case CHANGE_MODE:     
                return {
                    ...state,
                    mode: action.payload,
                    editId: action.payload === MODE.Edit ? action.editId : '',
                    breadcrumb: {
                        part1: MODE.List,
                        part2: action.payload
                    }
                }
            case BREADCRUMB_RETURN_HOME:
                return {
                    ...state,
                    mode: MODE.List,
                    breadcrumb: {
                        part1: MODE.List,
                        part2: state.breadcrumbHome,
                    }
                }
            case START:
                return {
                    ...state,
                    loading: true
                }
            case END:
                return {
                    ...state,
                    loading: false
                }
            default:
                return {
                    ...state
                }
        }
    };
    return crudReducer;
}

