import CRUD from './CRUD/CRUD';
import AuthenticationLayer from './Authentication/index';
import { appReducer } from './Authentication/Reducer';
import { CrudStateModel, FilterModel, CrudSettingModel, AuthSettingModel } from './CRUD/Model';
import { AppStateModel } from './Authentication/Model';

export default {
  components: {
    CRUD,
    AuthenticationLayer
  },
  reducers: {
    appReducer
  },
  models: {
    AppStateModel: AppStateModel,
    CrudStateModel: CrudStateModel, 
    FilterModel: FilterModel, 
    CrudSettingModel: CrudSettingModel, 
    AuthSettingModel: AuthSettingModel
  }  
}
