import React, { useEffect, useState } from 'react';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

function Edit(props: any) {
    const {crudState, dispatchCrud } = props.crudReducer;
    const Form = props.Form;

    const [thisCrud, setThisCrud] = useState(undefined);
    const loadOneCrudContainer = () => {
        props.loadOneCrud(props.appReducer, props.crudReducer, setThisCrud);
    }
    
    const useMountEffect = (fun: any) => useEffect(fun, []);
    useMountEffect(loadOneCrudContainer);
     
    const onSubmitEditing = (data: any) => {
        props.editCrud(props.appReducer, props.crudReducer, {
            ...data            
        });
        dispatchCrud({ type: 'BREADCRUMB_RETURN_HOME'})
    };
    const onSubmitDeleting = (data: any) => {
        confirmAlert({
            customUI: ({ onClose }) => {
              return (
                <div className='custom-delete-confirm-ui'>
                  <h1>Are you sure?</h1>
                  <p>You want to delete this?</p>
                  <button onClick={onClose}>No</button>
                  <button
                    onClick={() => {
                        props.deleteCrud(props.appReducer, props.crudReducer, {
                            ...data
                        });
                        dispatchCrud({ type: 'BREADCRUMB_RETURN_HOME'});
                        onClose();
                    }}
                  >
                    Yes, Delete it!
                  </button>
                </div>
              );
            }
          });
        
    }
    
    return (
        <div>
            <span><b>Edit Country</b></span>
            { !crudState.loading && thisCrud !== undefined ? <Form form={thisCrud} onSubmit={onSubmitEditing} onSubmitDeleting={onSubmitDeleting} /> : ''}

        </div>
    )
}

export default Edit;