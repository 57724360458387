const checkAuth = () => {
    const cache = localStorage.getItem('account');
    if (cache !== null && cache !== undefined) {
        return true;
    }
    return false;
};

const getAccountCache = () => {
    if (checkAuth()) {
        const account = localStorage.getItem('account');
        if (account !== null) {
            return JSON.parse(account);
        }
        return {};
    } else {
        return {};
    }
}

export class AppStateModel {
    constructor(
        auth: boolean = checkAuth(),
        account: any = getAccountCache(),
        loading: boolean = false,
        error: boolean = false,
        errorMessage: boolean = false
    ) {
        this.auth = auth;
        this.account = account;
        this.loading = loading;
        this.error = error;
        this.errorMessage = errorMessage;
    }

    auth: boolean;
    account: any;
    loading: boolean;
    error: boolean;
    errorMessage: boolean;
}