import { AppStateModel } from "./Model"

export const AUTH_START = 'START';

export interface AuthStartAction {
    type: typeof AUTH_START;
}

export const AUTH_END = 'END';
export interface AuthEndAction {
    type: typeof AUTH_END;
}

export const AUTH_CHANGE = 'CHANGE';
export interface AuthChangeAction {
    type: typeof AUTH_CHANGE;
    payload: any;
}

export const AUTH_LOGIN_ERROR = 'LOGIN_ERROR';
export interface AuthLoginErrorAction {
    type: typeof AUTH_LOGIN_ERROR;
    payload: string;
}

export const AUTH_LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export interface AuthLoginSuccessAction {
    type: typeof AUTH_LOGIN_SUCCESS;
    payload: any;
}

export const AUTH_LOGOUT = 'LOGOUT';
export interface AuthLogoutAction {
    type: typeof AUTH_LOGOUT;
}
export type AppActions = AuthStartAction |
                        AuthEndAction |
                        AuthChangeAction |
                        AuthLoginErrorAction |
                        AuthLoginSuccessAction |
                        AuthLogoutAction
                        ;

export const appReducer = (state: AppStateModel, action: AppActions) => {
    switch (action.type) {
      case 'START': 
        return {
            ...state,
            loading: true
        }      
      case 'END': 
        return {
            ...state,
            loading: false
        }
        case 'CHANGE': 
        return {
            ...state,
            ...action.payload
        }
        case 'LOGIN_ERROR':
            return {
                ...state,
                error: true,
                errorMessage: action.payload,
                loading: false,
                auth: false,
                account: {}
            }
        case 'LOGIN_SUCCESS':
            return {
                ...state,
                error: false,
                errorMessage: '',
                loading: false,
                auth: true,
                account: action.payload
            }
        case 'LOGOUT':
            return {
                ...state,
                error: false,
                errorMessage: '',
                loading: false,
                auth: false,
                account: {}
            }
      default: 
        return state;
      
    }
  }