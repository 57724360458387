import React from 'react';
import { Breadcrumb } from 'react-bootstrap';
function BreadcrumbComponent(props: any) {
    const { bcState, bcDispatch } = props.reducer;
    const part1Click = (value: string) => {
        bcDispatch({ type: 'BREADCRUMB_RETURN_HOME', payload: value })
    }
    return (
        <Breadcrumb>
        <Breadcrumb.Item onClick={() => part1Click(bcState.breadcrumb.part1)}>{ bcState.breadcrumb.part1 }</Breadcrumb.Item>        
        <Breadcrumb.Item active>{ bcState.breadcrumb.part2 }</Breadcrumb.Item>
        </Breadcrumb>
    )
}
export default BreadcrumbComponent;